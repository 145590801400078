import React from 'react'
import AnimatedLink from '../global/animatedLink';
import Background from '../../svg/homepage-background.svg';
import ListenerIcon from '../../svg/listener-icon.svg';
import { parseHTML } from './../../helpers/parseHTML';
import { useSelector } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';

export default function HomepageHero() {
  const data = heroData();
  const { firebaseCounts } = useSelector(state => state.siteState);

  function getCountsMarkup(counts) {
    let listeners = counts['listener-waiting'];
    let talkers = counts['talker-waiting'];
    if (listeners > 0) {
      return <span className={`homepage-hero__counts`}><ListenerIcon/>{`${listeners} ${listeners == 1 ? 'Listener is' : 'Listeners are'} waiting to chat`}</span> 
    } else if (talkers > 0) {
      return <span className={`homepage-hero__counts`}><ListenerIcon/>{`${talkers} ${talkers == 1 ? 'Talker is' : 'Talkers are'} waiting to chat`}</span> 
    } else {
      return null 
    }
  }
    
  return (
    <div className={`homepage-hero`}>
      <Background className={`homepage-hero__background`}/>
      <div className="outer-container">
        <div className="inner-container">
          <div className="homepage-hero__left">
            <h1>{data.title}</h1>
            <h2>{parseHTML(data.subtitle)}</h2>
            {data.cta.map((cta, index) => {
              return (
                <AnimatedLink 
                  className={`cta`} 
                  to={cta.linkUrl} 
                  key={index}
                  logEvent={`Homepage - Homepage hero CTA click`}
                >
                  {cta.linkTitle}
                </AnimatedLink>
              )
            })}
            {firebaseCounts !== false && firebaseCounts !== undefined ?
              getCountsMarkup(firebaseCounts)
            : null}
          </div>
          <div className="homepage-hero__right">
            <div 
              className="homepage-hero__right-image"
              style={{
                WebkitMaskImage: `url(../images/illustrations/herogirl.svg)`,
                backgroundImage: `url(../images/svg-texture.png), url(../images/illustrations/herogirl.svg)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const heroData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsHomeHero {
        title
        subtitle
        sideImage {
          alt
          url
        }
        cta {
          linkTitle
          linkUrl
        }
      }
    }
  `)
  return data
}