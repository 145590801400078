import React from 'react'
import Background from '../../svg/homepage-background.svg';
import { parseHTML } from './../../helpers/parseHTML';
import { useStaticQuery, graphql } from 'gatsby';

export default function HomepageExplainer() {
  let data = explainerData();
  
  return (
    <div className={`homepage-explainer`}>
      <Background className={`homepage-explainer__background`}/>
      <div className="outer-container">
        <div className="inner-container">
          <div className="homepage-explainer__left">
            <div 
              className="homepage-explainer__left-image"
              style={{
                WebkitMaskImage: `url(../images/illustrations/computerboy.svg)`,
                backgroundImage: `url(../images/svg-texture.png), url(../images/illustrations/computerboy.svg)`,
              }}
            ></div>
          </div>
          <div className="homepage-explainer__right">
            {parseHTML(data.explainerText)}
          </div>
        </div>
      </div>
    </div>
  )
}

const explainerData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsHomeExplainer {
        explainerText
        sideImage {
          alt
          url
        }
      }
    }
  `)
  return data
}