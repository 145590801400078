import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layouts/layout"
import SEO from "../components/layouts/seo"
import HomepageHero from "../components/homepage/homepage-hero"
import Footer from '../components/global/footer';
import Nav from '../components/global/nav';
import HomepageExplainer from '../components/homepage/homepage-explainer';
import queryString from 'query-string';
import animateScrollTo from 'animated-scroll-to';
import { Helmet } from 'react-helmet';

export default function IndexPage(props) {
  const { transitionStatus, data } = props;
  const { pageData } = data;

  useEffect(() => {
    checkForLinking();
  });

  function checkForLinking() {
    setTimeout(() => {
      const parsedQuerystring = queryString.parse(location.search);

      if (parsedQuerystring.scrollto !== undefined) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offset = document.querySelector('.homepage-explainer').getBoundingClientRect().top + scrollTop;
        // When top nav hides while scrolling down, sets the correct offset
        animateScrollTo(offset, {speed: 1500})
        
        // Remove query string      
        if (history.pushState) {
          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
          window.history.pushState({path:newurl},'',newurl);
        }
      }
    }, 500);
  } 

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://helpful.chat"
              }]
            }
          `}
        </script>
      </Helmet>
      <SEO
        title={pageData.title} 
        description={pageData.metaDescription} 
        ogDescription={pageData.ogDescription} 
        ogImage={pageData.ogImage?.url} 
        isHome={true}
      />
      <Nav extraClass={`home`} home={true}/>
      <Layout extraClass={`homepage`} transitionStatus={transitionStatus}>
        <HomepageHero/>
        <HomepageExplainer/>
        <Footer home={true}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    pageData: datoCmsHome {
      title
      ogDescription
      metaDescription
      ogImage {
        url
      }
    }
  }
`